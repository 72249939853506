import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);


const init = async () => {
  const submitBtn = document.getElementById('form-contact-submit');
  submitBtn.addEventListener('click', async e => {
    e.preventDefault();
    const formName = document.getElementsByName('contact-name')[0] ? document.getElementsByName('contact-name')[0].value : '';
    const formEmail = document.getElementsByName('contact-email')[0] ? document.getElementsByName('contact-email')[0].value : '';
    const formPhone = document.getElementsByName('contact-phone')[0] ? document.getElementsByName('contact-phone')[0].value : '';
    const formCompany = document.getElementsByName('contact-company')[0] ? document.getElementsByName('contact-company')[0].value : '';
    const formMessage = document.getElementsByName('contact-message')[0] ? document.getElementsByName('contact-message')[0].value : '';
    const formEmailTo = document.getElementsByName('contact-email-to')[0] ? document.getElementsByName('contact-email-to')[0].value : '';

    const blockRequst = document.getElementById('form-contact-request');
    const blockSuccess = document.getElementById('form-contact-success');

    // const response = await _axios.post('/v1/contact_us/email', {
    //   cid: window.erpPortalApiCompany,
    //   name: formName,
    //   phone: formPhone,
    //   email: formEmail,
    //   company: formCompany,
    //   message: formMessage,
    //   to: formEmailTo
    // });
    //
    // if (response.status) {
    //   blockRequst.style.display = 'none';
    //   blockSuccess.style.display = 'block';
    // }
    blockRequst.style.display = 'none';
    blockSuccess.style.display = 'block';
  });
};

export default () => {
  apos.util.widgetPlayers['contact-form'] = {
    selector: '[data-contact-form-widget]',
    player: function(el) {
      init();
    }
  };
};
